import * as Sentry from "@sentry/browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "typeface-inconsolata";
import "typeface-merriweather";
import "typeface-palanquin";
import App from "./app/App";
import "./index.css";
import registerServiceWorker, {unregister} from "./registerServiceWorker";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV
});

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.USE_SERVICE_WORKER) {
  registerServiceWorker();
} else {
  unregister();
}
