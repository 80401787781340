import * as React from "react";
import UserList from "./UserList";
import UserProfile from "./UserProfile";
import {Switch, Route} from "react-router";

const Users: React.FunctionComponent = () => (
  <Switch>
    <Route path="/settings/users/:id" component={UserProfile} />
    <UserList />
  </Switch>
);

export default Users;
