interface TextChunk {
  text: string;
  emote?: string;
}

// delimit emotes with colons
// capture group includes emotes themselves when splitting
const regex = /(:[\w-]+:)/;

export default function parse(text: string): TextChunk[] {
  // split into chunks of plain text and emotes
  const chunks = text.split(regex);

  return chunks
    .filter(chunk => chunk.length) // get rid of empty strings
    .map(chunk => ({
      text: chunk,
      emote: regex.test(chunk) ? chunk.replace(/:/g, "") : undefined // indicate chunks that are emotes
    }));
}
