import * as React from "react";
import {IconType} from "react-icons";
import {Link, Route, Switch} from "react-router-dom";
import "./SidebarNavigationLink.css";

interface SidebarNavLinkProps {
  to: string;
  icon: IconType;
  title?: string;
}

const SidebarNavigationLink: React.FunctionComponent<SidebarNavLinkProps> = ({
  to,
  icon: Icon,
  title
}) => {
  const renderLink = () => (
    <Link to="/chat" title={title}>
      <Icon />
    </Link>
  );

  return (
    <div className="SidebarNavigationLink">
      <Switch>
        <Route path={to} render={renderLink} />
        <Route>
          <Link to={to} title={title}>
            <Icon />
          </Link>
        </Route>
      </Switch>
    </div>
  );
};

export default SidebarNavigationLink;
