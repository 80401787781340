import * as React from "react";
import Alert from "./Alert";
import alerts, {Alert as AlertItem} from "./alerts";

interface AlertListState {
  alerts: AlertItem[];
}

class AlertList extends React.Component<{}, AlertListState> {
  constructor(props: object) {
    super(props);

    this.state = {alerts: []};
  }

  componentDidMount() {
    alerts.subscribe(alertList => {
      this.setState({alerts: alertList});
    });
  }

  close(key: number) {
    alerts.close(key);
  }

  render() {
    return (
      <div className="AlertList">
        {this.state.alerts.map((alert, index) => (
          <Alert alert={alert} key={index} index={index} onClose={this.close} />
        ))}
      </div>
    );
  }
}

export default AlertList;
