import * as React from "react";
import helpers from "../../../utils/form-helpers";
import "./BanForm.css";

interface BanFormProps {
  banned: boolean;
  onBan: (minutes?: number) => void;
  onUnban: () => void;
}

interface BanFormState {
  banMinutes?: string;
}

class BanForm extends React.Component<BanFormProps, BanFormState> {
  handleChange = helpers.handleChange.bind(this);
  state: BanFormState = {};

  ban = async () => {
    const {onBan} = this.props;
    onBan(this.getBanMinutes());
  };

  getBanMinutes = () => {
    const {banMinutes} = this.state;
    if (banMinutes) {
      return Math.min(parseInt(banMinutes, 10), 10000000000);
    }

    return undefined;
  };

  render = () => {
    const {banned, onUnban} = this.props;

    return (
      <div className="BanForm">
        <input
          name="banMinutes"
          type="tel"
          pattern="[0-9]*"
          placeholder="Minutes"
          onChange={this.handleChange}
          value={this.getBanMinutes() || ""}
        />
        {banned || <button onClick={this.ban}>Ban</button>}
        {banned && <button onClick={onUnban}>Unban</button>}
        <p>Leave blank for a permanent ban</p>
      </div>
    );
  };
}

export default BanForm;
