import api, {withAlert} from "../services/api";
import {UserItem} from "./User";
import events from '../utils/events';

interface UserListResult {
  total: number;
  list: UserItem[];
}

export interface Ignore {
  id: number;
  name: string;
  images_only: boolean;
}

type OnIgnore = () => void;

const [listeners, subscribeIgnore, unsubscribeIgnore] = events.create<OnIgnore>();

export default {
  /**
   * Returns the list of online users
   */
  getOnline(): Promise<UserItem[]> {
    return withAlert(() => {
      return api.fetchJson("/users/online");
    }, "Failed to load online users list");
  },

  /**
   * Returns a paginated list of registered users
   */
  getRegistered(page: number, size: number): Promise<UserListResult> {
    return withAlert(() => {
      return api.fetchJson(`/users/list/${page}?size=${size}`);
    }, "Failed to load users");
  },

  /**
   * Returns a user by ID
   */
  getOne(id: number): Promise<UserItem | null> {
    return withAlert(() => {
      return api.fetchJson(`/users/${id}`);
    }, "Failed to load user data");
  },

  /**
   * Updates the ignore status of a user
   */
  ignore(id: number, status: string) {
    return withAlert(async () => {
      await api.fetchJson(`/ignores/${id}`, {method: "PUT", body: {status}});

      listeners.forEach(cb => cb());
    });
  },

  /**
   * Returns a list of ignored users
   */
  getIgnoreList(): Promise<Ignore[]> {
    return withAlert(async () => {
      const result = await api.fetchJson(`/ignores`);

      return result.list;
    });
  },

  /**
   * Returns the current ignore status for a user
   */
  async getIgnoreStatus(id: number) {
    const ignores = await this.getIgnoreList();
    const ignore = ignores.find(i => i.id === id);
    if (!ignore) {
      return "unignored";
    }

    return ignore.images_only ? "images_only" : "ignored";
  },

  /**
   * Bans a user
   */
  ban(user: UserItem, minutes?: number) {
    return withAlert(() => {
      return api.fetchJson(`/bans/${user.id}`, {
        method: "POST",
        body: {duration: minutes}
      });
    });
  },

  /**
   * Unbans a user
   */
  unban(user: UserItem) {
    return withAlert(() => {
      return api.fetchJson(`/bans/${user.id}`, {method: "DELETE"});
    });
  },

  /**
   * Updates a user's role
   */
  setRole(user: UserItem, role: string) {
    return withAlert(() => {
      return api.fetchJson(`/users/${user.id}/role`, {
        method: "PUT",
        body: {role}
      });
    });
  },

  /**
   * Merges a user into an array of users, preventing duplicates
   */
  add(users: UserItem[], user: UserItem) {
    if (users.some(i => i.id === user.id)) {
      return users;
    }

    return users.concat(user);
  },

  /**
   * Removes a user from an array of users
   */
  remove(users: UserItem[], user: number) {
    return users.filter(i => i.id !== user);
  },

  /**
   * Manage listeners for changes to ignore status
   */
  subscribeIgnore,
  unsubscribeIgnore
};
