import alerts from "../alerts/alerts";
import auth from "../auth/auth";

type OnMessageCallback = (message: any) => void;
type OnReconnectCallback = () => void;

function timeout(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

let tries = 0;

export default {
  websocket: undefined as WebSocket | undefined,
  messageListeners: [] as OnMessageCallback[],
  reconnectListeners: [] as OnReconnectCallback[],

  /**
   * Establishes a websocket connection and authenticates with the local token
   */
  async connect() {
    const location = window.location;
    const protocol = location.protocol === "https:" ? "wss" : "ws";
    const promise = new Promise((resolve, reject) => {
      const port = process.env.REACT_APP_API_PORT || window.location.port
      const path = process.env.REACT_APP_WS_PATH || '';
      const ws = new WebSocket(
        `${protocol}://${window.location.hostname}:${port}${path}`
      );
      this.websocket = ws;

      ws.addEventListener("open", resolve);
      ws.addEventListener("error", reject);

      ws.addEventListener("close", () => {
        if (!this.websocket) {
          // if disconnecting intentionally, don't reconnect
          return;
        }

        alerts.send({
          message: "Disconnected, attempting to reconnect...",
          clear: true
        });

        this.reconnect();
      });

      ws.addEventListener("message", event => {
        const data = JSON.parse(event.data);

        if (data.type === "banned") {
          // if banned, skip any other listeners and log out
          auth.logout();

          return;
        }

        this.messageListeners.forEach(callback => callback(data));
      });
    });

    await promise;
    if (this.websocket) {
      this.send({type: "auth", token: localStorage.getItem("token")});
    }

    return promise;
  },

  /**
   * Reconnects after a disconnect
   */
  async reconnect() {
    // wait an exponentially increasing, randomly fluctuating period to attempt further reconnects
    const wait = 500 * Math.exp(tries / 2) * (0.8 + 0.4 * Math.random());

    await timeout(wait);

    try {
      await this.connect();
      tries = 0;
      alerts.clear();
      this.reconnectListeners.forEach(callback => callback());
    } catch (event) {
      if (tries < 5) {
        tries++;
      }
    }
  },

  /**
   * Returns whether the websocket connection is active
   */
  isConnected() {
    return !!this.websocket;
  },

  /**
   * Disconnects from the websocket if the connection is active
   */
  disconnect() {
    const ws = this.websocket;
    if (!ws) {
      return;
    }

    delete this.websocket;
    this.messageListeners = [];
    this.reconnectListeners = [];
    ws.close();
  },

  /**
   * Sends a message through the active connection
   */
  async send(message: string | object) {
    if (!this.websocket) {
      throw new Error("Not connected");
    }

    if (this.websocket.readyState !== 1) {
      throw new Error("Connection not ready");
    }

    if (typeof message === "object") {
      message = JSON.stringify(message);
    }

    this.websocket.send(message);
  },

  /**
   * Registers a message listener, called whenever a message is received
   */
  onMessage(callback: OnMessageCallback) {
    this.messageListeners.push(callback);
  },

  /**
   * Registers a reconnect listener, called when the socket reconnects after a disconnection
   */
  onReconnect(callback: OnReconnectCallback) {
    this.reconnectListeners.push(callback);
  },

  /**
   * Removes previously registered listeners
   */
  removeListeners(
    ...callbacks: Array<OnMessageCallback | OnReconnectCallback>
  ) {
    this.messageListeners = this.messageListeners.filter(
      listener => !callbacks.includes(listener)
    );
    this.reconnectListeners = this.reconnectListeners.filter(
      listener => !callbacks.includes(listener)
    );
  }
};
