import * as React from "react";

interface IgnoreFormProps {
  status: string;
  onChange: (status: string) => void;
}

const IgnoreForm: React.FunctionComponent<IgnoreFormProps> = ({
  status,
  onChange
}) => {
  const change = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(evt.target.value);
  };

  return (
    <select onChange={change} value={status}>
      <option value="unignored">Not Ignored</option>
      <option value="images_only">Don't Show Inline Images</option>
      <option value="ignored">Ignored</option>
    </select>
  );
};

export default IgnoreForm;
