import * as React from "react";
import auth from "../auth/auth";
import "./Logout.css";

const Logout = () => (
  <div className="Logout">
    <button onClick={auth.logout}>Log out</button>
  </div>
);

export default Logout;
