import * as React from "react";
import GuestGate from "../common/GuestGate";
import panes from "./panes/config";
import SettingsCategory from "./SettingsCategory";
import "./SettingsNav.css";

const SettingsNav: React.FunctionComponent = () => (
  <div className="SettingsNav">
    <h3>Settings</h3>
    {panes.map(([label, path]) => (
      <SettingsCategory to={path} name={label} key={path} />
    ))}

    {/* Hide users pane for guests when guest access is off */}
    <GuestGate>
      <SettingsCategory to="/settings/users" name="Users" />
    </GuestGate>
  </div>
);

export default SettingsNav;
