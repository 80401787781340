import * as React from "react";
import {ComponentState} from "react";

type NamedInputElement = HTMLInputElement | HTMLTextAreaElement;

const helpers = {
  handleChange<E extends NamedInputElement>(e: React.FormEvent<E>) {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as ComponentState);
  },
  setState: React.Component.prototype.setState
};

export default helpers;
