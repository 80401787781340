import he from "he";
import * as React from "react";
import ContentEditable, {ContentEditableEvent} from "react-contenteditable";
import sanitizeHtml, {IOptions} from "sanitize-html";
import LoadIndicator from "../common/LoadIndicator";
import postEvents, {Wrapper} from "./post-events";
import postSelection from "./post-selection";
import "./PostInput.css";

interface PostInputState {
  post: string;
  sending: boolean;
}

interface PostInputProps {
  onSend: (message: string) => Promise<boolean>;
}

/**
 * Configure sanitizer to strip all tags and attributes, we want plain text only
 */
const sanitizeConfig: IOptions = {
  allowedTags: [],
  allowedAttributes: {}
};

class PostInput extends React.Component<PostInputProps, PostInputState> {
  state = {post: "", sending: false};

  inputRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    postEvents.subscribe(this.onInsertText, this.onWrap);
  }

  componentWillUnmount() {
    postEvents.unsubscribe(this.onInsertText, this.onWrap);
  }

  handleChange = (evt: ContentEditableEvent) => {
    if (evt.type !== "input") {
      return;
    }

    const sanitized = sanitizeHtml(evt.target.value, sanitizeConfig);

    this.setState({post: sanitized});
  };

  /**
   * Focuses the input textarea and scrolls to the bottom (if scrollable)
   */
  focusAndScroll(textarea: HTMLDivElement) {
    textarea.focus();
    textarea.scrollTop = textarea.scrollHeight;

    postSelection.moveCursorToEnd(textarea);
  }

  /**
   * Handles text insertion events, e.g. appending a quoted post
   */
  onInsertText = (text: string) => {
    const textarea = this.inputRef.current;
    if (!textarea) {
      return;
    }

    this.setState(
      ({post}) => {
        if (post.trim() === "") {
          // Fix for phantom newline when quoting after deleting the contents of the post input
          post = "";
        }

        return {post: post + he.encode(text)};
      },
      () => this.focusAndScroll(textarea)
    );
  };

  /**
   * Handles selection wrapping events, e.g. surrounding a URL with inline image markdown
   */
  onWrap = (wrap: Wrapper) => {
    const textarea = this.inputRef.current;
    if (!textarea) {
      return;
    }

    // keep track of previous text, need to know if it contained a newline
    const {post} = this.state;

    const selection = window.getSelection();
    const selectedText = selection ? he.encode(selection.toString()) : "";

    const {replacement, start, end} = wrap(selectedText);

    textarea.focus();
    // Using insertHTML over insertText to preserve line breaks, even though no tags are inserted
    document.execCommand("insertHTML", false, replacement);
    if (!selectedText) {
      // Focus/scroll before changing selection because focusAndScroll also tries to set the selection
      this.focusAndScroll(textarea);

      postSelection.selectInnerText(
        textarea,
        post,
        replacement,
        start,
        end,
        selection
      );
    }
  };

  /**
   * Sends the post when the enter key is pressed
   */
  handleEnterPressed = async (e: React.KeyboardEvent) => {
    if (e.key !== "Enter" || e.shiftKey) {
      // Ignore other keys besides enter
      // Allow typing newlines while holding down shift
      return;
    }

    if (this.state.sending) {
      // Don't double-send posts
      return;
    }

    e.preventDefault();

    // Strip excess white space before sending
    const post = this.state.post.trim();
    if (post === "") {
      // Don't send pure-whitespace empty posts
      return;
    }

    try {
      // Block further attempts to send while it's in progress
      this.setState({sending: true});

      const decoded = he.decode(post);
      const sent = await this.props.onSend(decoded);
      if (sent) {
        // Clear post input on success
        this.setState({post: ""});
      }
    } finally {
      // Success or fail, allow submission again
      this.setState({sending: false});
    }
  };

  render() {
    return (
      <div className="PostInput">
        <ContentEditable
          innerRef={this.inputRef}
          tabIndex={0}
          className="inner"
          html={this.state.post}
          onChange={this.handleChange}
          onKeyDown={this.handleEnterPressed}
        />
        {this.state.sending && <LoadIndicator />}
      </div>
    );
  }
}

export default PostInput;
