import * as React from "react";
import {UserConsumer} from "../auth/UserContext";
import LoadIndicator from "../common/LoadIndicator";
import {Settings, SettingsConsumer} from "../common/SettingsContext";
import {UserItem} from "../users/User";

type Renderer = (settings: Settings, user: UserItem) => React.ReactNode;

interface Props {
  spinner?: boolean;
  children: Renderer;
}

function doRender(
  render: Renderer,
  spinner?: boolean,
  settings?: Settings,
  user?: UserItem
) {
  if (!user || !settings) {
    if (!spinner) {
      return null;
    }

    return <LoadIndicator />;
  }

  return render(settings, user);
}

const UserSettingsConsumer: React.FunctionComponent<Props> = ({
  spinner,
  children
}) => (
  <UserConsumer>
    {user => (
      <SettingsConsumer>
        {settings => doRender(children, spinner, settings, user)}
      </SettingsConsumer>
    )}
  </UserConsumer>
);

export default UserSettingsConsumer;
