import * as React from "react";
import User, {UserItem} from "../users/User";
import "./MessageHeader.css";
import {UserConsumer} from "../auth/UserContext";

interface Props {
  user: UserItem;
}

const MessageHeader: React.FunctionComponent<Props> = ({user}) => {
  return (
    <UserConsumer>
      {sessionUser => (
        <div className="MessageHeader">
          You are chatting privately with <User user={user} />
          {sessionUser && sessionUser.id === user.id && " (yourself). You can use this area as a scratch pad."}
        </div>
      )}
    </UserConsumer>
  );
};

export default MessageHeader;
