const subscribers: AlertCallback[] = [];
const alerts: Alert[] = [];

type AlertCallback = (alerts: Alert[]) => void;

export interface Alert {
  message?: string;
  type?: string;
  clear?: boolean;
}

function update() {
  subscribers.forEach(callback => callback(alerts));
}

export default {
  /**
   * Registers a callbackk to listen for changes to the list of alerts
   */
  subscribe(callback: AlertCallback) {
    subscribers.push(callback);
    callback(alerts);
  },

  /**
   * Sends an alert
   */
  send(alert: Alert) {
    if (alert.clear) {
      alerts.splice(0);
    }

    alerts.push(alert);
    update();
  },

  /**
   * Sends a simple alert with default options
   */
  sendMsg(message: string) {
    this.send({message});
  },

  /**
   * Removes an alert
   */
  close(index: number) {
    alerts.splice(index, 1);
    update();
  },

  /**
   * Removes all alerts
   */
  clear() {
    alerts.splice(0);
    update();
  }
};
