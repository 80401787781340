import api, {withAlert} from "../services/api";
import alerts from "../alerts/alerts";

export interface EmoteMap {
  [emote: string]: string;
}

export default {
  async get(): Promise<EmoteMap> {
    try {
      const json = await withAlert(
        () => api.fetchJson("/emotes"),
        "Failed to load emotes"
      );

      return json.emotes;
    } catch (error) {
      return {};
    }
  },

  async create(image: any, name: string) {
    const body = new FormData();
    body.append("image", image);
    body.append("name", name);

    try {
      await api.fetchJson("/emotes", {method: "POST", body, noJson: true});

      alerts.send({message: "Emote uploaded successfully", clear: true});
    } catch (err) {
      alerts.send({
        message: err.status === 400 ? "Emote name already in use" : err.message,
        clear: true
      });

      return false;
    }

    return true;
  }
};
