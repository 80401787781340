import api, {withAlert} from "../services/api";
import {Settings} from "./SettingsContext";

export default {
  defaults: {
    allow_guests_in_chat: true
  },
  async get(): Promise<Settings> {
    try {
      const json = await withAlert(
        () => api.fetchJson("/settings"),
        "Failed to load settings"
      );

      return json.settings;
    } catch (error) {
      return this.defaults;
    }
  }
};
