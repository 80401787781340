import * as React from "react";
import Dropzone from "react-dropzone";
import "./ImageDrop.css";

interface ImageDropProps {
  onDrop?: (file: File) => void;
}

interface ImageDropState {
  image?: any;
}

class ImageDrop extends React.Component<ImageDropProps, ImageDropState> {
  constructor(props: ImageDropProps) {
    super(props);

    this.state = {};
  }

  onDrop = (files: File[]) => {
    const file = files[0];
    const {onDrop} = this.props;

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({image: reader.result});
    };

    reader.readAsDataURL(file);

    if (onDrop) {
      onDrop(file);
    }
  };

  getClasses = (
    isActive: boolean,
    isRejected: boolean,
    isAccepted: boolean
  ) => {
    const classes = ["ImageDrop"];
    if (isRejected) {
      classes.push("reject");
    }
    if (isAccepted) {
      classes.push("accept");
    }
    if (isActive) {
      classes.push("active");
    }
    return classes.join(" ");
  };

  renderDropContents = (isDragReject: boolean, image: any) => {
    if (image) {
      return <img src={image} alt="preview" />;
    }

    if (isDragReject) {
      return <div>File must be a png/jpg/gif</div>;
    }

    return <div>Upload image...</div>;
  };

  render() {
    const {image} = this.state;

    return (
      <Dropzone
        accept="image/png, image/jpeg, image/gif"
        multiple={false}
        onDropAccepted={this.onDrop}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        }) => (
          <div
            {...getRootProps()}
            className={this.getClasses(
              isDragActive,
              isDragReject,
              isDragAccept
            )}
          >
            <input {...getInputProps()} />
            {this.renderDropContents(isDragReject, image)}
          </div>
        )}
      </Dropzone>
    );
  }
}

export default ImageDrop;
