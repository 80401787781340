/**
 * Contains methods for altering the selection range in post input
 */
export default {
  /**
   * Selects the inner (placeholder) text from an inserted markdown snippet
   */
  selectInnerText(
    textarea: HTMLDivElement,
    post: string,
    replacement: string,
    start: number,
    end: number,
    selection: Selection | null
  ) {
    if (!selection) {
      return;
    }

    // Select inner value of inserted text
    const range = document.createRange();

    // Find last text node containing the replacement string
    // Fails if inserting before an identical string but that's an edge case
    const textNodes: ChildNode[] = [].slice.call(textarea.childNodes).reverse();

    const textNode = textNodes.find(
      node =>
        node.textContent !== null && node.textContent.includes(replacement)
    );

    if (!textNode) {
      return;
    }

    // If post contained a newline prior to insertion, need to account for the extra newline that will be added
    const offset = post.includes("\n") ? 1 : 0;

    const length = textNode.textContent ? textNode.textContent.length : 0;
    range.setStart(textNode, length - start - offset);
    range.setEnd(textNode, length - end - offset);

    selection.removeAllRanges();
    selection.addRange(range);
  },

  /**
   * Moves the cursor to the end of the post input field
   */
  moveCursorToEnd(textarea: HTMLDivElement) {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    const range = document.createRange();
    range.setStart(textarea, 1);
    range.setEnd(textarea, 1);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};
