import * as React from "react";
import users, {Ignore} from "../users/users";

const {Provider, Consumer} = React.createContext<Ignore[]>([]);

interface IgnoresProps {
  ignores: Ignore[];
}

export class IgnoresProvider extends React.Component<{}, IgnoresProps> {
  state = {ignores: []};

  async componentDidMount() {
    await this.loadIgnores();

    users.subscribeIgnore(this.loadIgnores);
  }

  componentWillUnmount() {
    users.unsubscribeIgnore(this.loadIgnores);
  }

  loadIgnores = async () => {
    const ignores = await users.getIgnoreList();

    this.setState({ignores});
  };

  render() {
    return (
      <Provider value={this.state.ignores}>{this.props.children}</Provider>
    );
  }
}

export {Consumer as IgnoresConsumer};
