import * as React from "react";
import TweetEmbed from "react-tweet-embed";

interface LinkRendererProps {
  href: string;
  title?: string;
  target?: string;
}

const LinkRenderer: React.FunctionComponent<LinkRendererProps> = props => {
  const {href, children} = props;

  // Check for twitter links and render an embed widget instead
  const tweet = href.match(/:\/\/[^/]*twitter\.com\/[^/]+\/status\/([0-9]+)/);

  if (tweet) {
    const id = tweet[1];
    if (id) {
      return (
        <TweetEmbed
          id={id}
          options={{
            dnt: true,
            theme: "dark",
            cards: "hidden",
            conversation: "none"
          }}
        />
      );
    }
  }

  return (
    <a {...props} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default LinkRenderer;
