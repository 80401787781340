import * as React from "react";

interface RoleFormProps {
  role: string;
  onChange: (role: string) => void;
}

const RoleForm: React.FunctionComponent<RoleFormProps> = ({role, onChange}) => {
  const change = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(evt.target.value);
  };

  return (
    <select onChange={change} value={role}>
      <option value="user">User</option>
      <option value="member">Member</option>
      <option value="moderator">Moderator</option>
      <option value="admin">Admin</option>
    </select>
  );
};

export default RoleForm;
