import * as React from "react";

interface Props {
  src: string;
  alt?: string;
}

const ImageRenderer = (inline: boolean) => ({src, alt, ...rest}: Props) => {
  let img: React.ReactNode = alt || src;
  if (inline) {
    img = <img src={src} alt={alt} {...rest} />;
  }

  return (
    <a href={src} target="_blank" rel="noopener noreferrer">
      {img}
    </a>
  );
};

export default ImageRenderer;
