import * as React from "react";
import DataTable from "react-data-table-component";
import LoadIndicator from "../../../common/LoadIndicator";
import history from "../../../router/history";
import {UserItem} from "../../../users/User";
import users from "../../../users/users";
import helpers from "../../../utils/form-helpers";
import "./UserList.css";
import errors from "../../../utils/errors";

interface UsersState {
  list: UserItem[];
  size: number;
  total: number;
  loading: boolean;
  search: string;
}

const columns = [
  {name: "ID", selector: "id", sortable: true, maxWidth: "100px"},
  {name: "Name", selector: "name", sortable: true},
  {name: "Role", selector: "role", sortable: true}
];

class UserList extends React.Component<{}, UsersState> {
  handleChange = helpers.handleChange.bind(this);

  constructor(props: object) {
    super(props);

    this.state = {
      list: [],
      size: 20,
      total: 0,
      loading: false,
      search: ""
    };
  }

  componentDidMount() {
    return this.handlePageChange(1);
  }

  handlePageChange = (page: number) =>
    errors.ignore(async () => {
      const {size} = this.state;
      this.setState({loading: true});
      const {total, list} = await users.getRegistered(page, size);
      this.setState({
        loading: false,
        list,
        total
      });
    });

  handlePerRowsChange = (perPage: number, page: number) =>
    errors.ignore(async () => {
      this.setState({loading: true});
      const {list} = await users.getRegistered(page, perPage);
      this.setState({
        loading: false,
        size: perPage,
        list
      });
    });

  handleRowClick = (user: UserItem) => {
    history.push(`/settings/users/${user.id}`);
  };

  render() {
    const {list, size, total, search, loading} = this.state;
    const filtered = list.filter(user => {
      const lowerSearch = search.toLowerCase();
      return (
        user.name.toLowerCase().includes(lowerSearch) ||
        user.role.toLowerCase().includes(lowerSearch) ||
        user.id.toString() === search
      );
    });

    return (
      <div className="UserList">
        <DataTable
          title="Users"
          columns={columns}
          data={filtered}
          pagination
          paginationServer
          paginationPerPage={size}
          paginationRowsPerPageOptions={[10, 20, 50]}
          paginationTotalRows={total}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handlePerRowsChange}
          onRowClicked={this.handleRowClick}
          subHeader
          subHeaderAlign="left"
          subHeaderComponent={
            <input
              name="search"
              type="text"
              placeholder="Search"
              onChange={this.handleChange}
            />
          }
          className="users-table"
        />
        {loading && <LoadIndicator />}
      </div>
    );
  }
}

export default UserList;
