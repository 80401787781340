import * as React from "react";

interface AuthFieldsProps {
  email: string;
  password: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const AuthFields: React.FunctionComponent<AuthFieldsProps> = ({
  email,
  password,
  onChange
}) => (
  <div>
    <div className="form-item">
      <input
        type="text"
        name="email"
        placeholder="Email"
        value={email}
        onChange={onChange}
        autoFocus
        required
      />
    </div>

    <div className="form-item">
      <input
        type="password"
        name="password"
        placeholder="Password"
        value={password}
        onChange={onChange}
        required
      />
    </div>
  </div>
);

export default AuthFields;
