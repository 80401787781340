import * as React from "react";
import auth from "../auth/auth";
import SidebarNavigation from "../chat/SidebarNavigation";
import CloseButton from "../common/CloseButton";
import LoadIndicator from "../common/LoadIndicator";
import MessageHeader from "../messages/MessageHeader";
import websocket from "../services/websocket";
import {UserItem} from "../users/User";
import Jump from "./Jump";
import jumpToBottom from "./jump-to-bottom";
import {PostItem} from "./Post";
import postScroll from "./post-scroll";
import "./PostContainer.css";
import PostInput from "./PostInput";
import PostList from "./PostList";
import posts from "./posts";

interface Message {
  type?: string;
}

interface DeleteMessage {
  type: "delete";
  id: number;
}

interface ChatmineMessage {
  type: "chatmine";
  id: number;
}

interface Props {
  user?: UserItem;
  closeable?: boolean;
}

interface PostContainerState {
  posts: PostItem[];
  loading: boolean;
  live: boolean;
}

class PostContainer extends React.Component<Props, PostContainerState> {
  state = {
    posts: [],
    loading: true,
    live: true
  };

  private wrapperRef = React.createRef<HTMLDivElement>();

  async componentDidMount() {
    await this.loadPosts(() => {
      if (postScroll.isFirefoxOrIE()) {
        this.scrollBottom();
      }
    });

    websocket.onReconnect(this.loadPosts);
    websocket.onMessage(this.onMessage);

    postScroll.scrollOnResize(this.scrollBottom);
    jumpToBottom.subscribeJump(this.scrollBottom);

    const {user} = this.props;
    if (!user) {
      posts.subscribeVote(this.onVote);
    }
  }

  componentWillUnmount() {
    websocket.removeListeners(this.loadPosts, this.onMessage);

    postScroll.cancelScrollOnResize(this.scrollBottom);
    jumpToBottom.unsubscribeJump(this.scrollBottom);

    const {user} = this.props;
    if (!user) {
      posts.unsubscribeVote(this.onVote);
    }
  }

  getSnapshotBeforeUpdate(prevProps: {}, prevState: PostContainerState) {
    // Calculate pre-render distance to determine whether scrolling is live or not
    const {current} = this.wrapperRef;
    if (current && prevState.posts.length < this.state.posts.length) {
      return postScroll.getScrollDistance(current);
    }

    return null;
  }

  componentDidUpdate(
    prevProps: {},
    prevState: PostContainerState,
    snapshot: number | null
  ) {
    postScroll.scrollOnImgLoad(
      this.wrapperRef.current,
      this.scrollBottom,
      snapshot
    );
  }

  userId() {
    const {user} = this.props;
    let userId;
    if (user) {
      userId = user.id;
    }

    return userId;
  }

  /**
   * Loads all posts or posts since latest one loaded
   */
  loadPosts = async (afterAdd?: () => void) => {
    const data = await posts.get(this.state.posts, this.userId());
    this.addPosts(data, afterAdd);
  };

  /**
   * Scrolls to the bottom of the list of posts
   */
  scrollBottom = () => {
    const {current} = this.wrapperRef;
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  };

  /**
   * Updates scroll status to determine whether the "jump to bottom" button should be visible
   */
  onScroll = () => {
    const {current} = this.wrapperRef;
    if (current) {
      const isLive = postScroll.isLive(postScroll.getScrollDistance(current));
      jumpToBottom.scroll(isLive);
    }
  };

  /**
   * Handler for messages (new posts or deleted posts)
   */
  onMessage = (message: Message) => {
    if (this.props.user && message.type !== "private") {
      // ignore non-private messages while chatting privately
      return;
    }

    if (message.type === "delete") {
      const deleteMessage = message as DeleteMessage;
      this.setState(prevState => {
        const filtered = prevState.posts.filter(
          post => post.id !== deleteMessage.id
        );

        return {posts: filtered};
      });
    }

    if (message.type === "chatmine") {
      const chatmine = message as ChatmineMessage;
      this.updatePost(chatmine.id, post => {
        if (post.chatmine_pending) {
          post.chatmined = true;
        } else {
          post.chatmine_pending = true;
        }
      });
    }

    const postMessage = message as PostItem;
    if ((!message.type || message.type === "private") && postMessage.post) {
      // Add flag for private messages so we can handle them differently in public chat
      if (message.type === "private") {
        postMessage.isPrivate = true;

        const {user} = this.props;
        const me = auth.getUser()?.id;
        if (!user && postMessage.poster_id === me) {
          // don't show private messages from yourself in main chat
          return;
        }

        if (user && ![me, user.id].includes(postMessage.poster_id)) {
          // ignore private messages from other users while chatting privately
          return;
        }
      }

      if (postMessage.id === 0) {
        postMessage.id = Math.random() * -1000000000;
      }

      // If in live mode (due to sending a new post), scroll to bottom
      // We do this after adding the next new post to avoid a potential race condition if we try to scroll beforehand
      const {live} = this.state;
      let afterAdd;
      if (live) {
        afterAdd = this.scrollBottom;
      }

      this.addPosts([postMessage], afterAdd);
    }
  };

  /**
   * Handler for voting (when current user votes to chatmine)
   */
  onVote = (id: number) => {
    this.updatePost(id, post => {
      post.voted = true;
    });
  };

  /**
   * Updates a single post in the list
   */
  updatePost(id: number, op: (post: PostItem) => void) {
    this.setState(prevState => {
      const updated = prevState.posts.map(post => {
        if (post.id === id) {
          op(post);
        }

        return post;
      });

      return {posts: updated};
    });
  }

  /**
   * Adds posts to list
   *
   * Resets live mode to false
   */
  addPosts(newPosts: PostItem[], afterAdd?: () => void) {
    this.setState(
      prevState => ({
        posts: posts.merge(prevState.posts, newPosts),
        loading: false,
        live: false
      }),
      afterAdd
    );
  }

  /**
   * Submits a new post or private message
   *
   * @param post
   */
  send = async (post: string) => {
    this.setState({
      live: true
    });

    let sent = false;
    try {
      sent = await posts.send(post, this.userId());
    } catch (error) {
      return false;
    }

    this.scrollBottom();

    return sent;
  };

  render() {
    const {posts: postList, loading} = this.state;
    const {user, closeable} = this.props;

    return (
      <div className="PostContainer">
        <Jump />
        {closeable && <CloseButton />}
        {!!user && <MessageHeader user={user} />}
        <div
          className="post-wrapper"
          ref={this.wrapperRef}
          onScrollCapture={this.onScroll}
        >
          {loading && <LoadIndicator />}
          <PostList isPrivate={!!user} posts={postList} />
        </div>

        <div className="input-container">
          <PostInput onSend={this.send} />
          {!!user || <SidebarNavigation />}
        </div>
      </div>
    );
  }
}

export default PostContainer;
