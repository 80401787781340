import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

// using CJS version instead of ESM for Jest compatibility
import {dracula} from "react-syntax-highlighter/dist/cjs/styles/hljs";

interface CodeProps {
  value: string;
  language?: string;
}

const Code: React.FunctionComponent<CodeProps> = ({value, language}) => {
  if (!value) {
    // don't try to render a syntax highlighter without a value
    return (
      <SyntaxHighlighter style={dracula}>
        Empty/malformed code tag
      </SyntaxHighlighter>
    );
  }

  return (
    <SyntaxHighlighter language={language} style={dracula}>
      {value}
    </SyntaxHighlighter>
  );
};

export default Code;
