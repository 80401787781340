import events from "../utils/events";

export type OnJump = () => void;
export type OnScroll = (live: boolean) => void;

const [jumpListeners, subscribeJump, unsubscribeJump] = events.create<OnJump>();

const onScrollEvent = events.create<OnScroll>();
const [scrollListeners, subscribeScroll, unsubscribeScroll] = onScrollEvent;

export default {
  /**
   * Fires an event to jump to the bottom of chat
   */
  jump() {
    jumpListeners.forEach(listener => listener());
  },
  subscribeJump,
  unsubscribeJump,

  /**
   * Fires an event to update whether the scroll position of chat is at or near the bottom (i.e. live)
   */
  scroll(live: boolean) {
    scrollListeners.forEach(listener => listener(live));
  },
  subscribeScroll,
  unsubscribeScroll
};
