import * as React from "react";
import "./Alert.css";
import {Alert as AlertItem} from "./alerts";

interface AlertProps {
  alert: AlertItem;
  onClose: (key: number) => void;
  index: number;
}

const Alert: React.FunctionComponent<AlertProps> = ({alert, index, onClose}) => {
  const closeHandler = () => {
    onClose(index);
  };

  return (
    <div className={`Alert ${alert.type || ""}`}>
      <button className="close" onClick={closeHandler}>
        &times;
      </button>
      <span className="message">{alert.message}</span>
    </div>
  );
};

export default Alert;
