import api, {withAlert} from "../services/api";
import websocket from "../services/websocket";
import events from "../utils/events";
import {PostItem} from "./Post";

type OnVote = (id: number) => void;

const [voteListeners, subscribeVote, unsubscribeVote] = events.create<OnVote>();

const MAX_POST_LENGTH = 1000;

export default {
  /**
   * Fetches recent posts or private messages
   *
   * Only returns new posts since the last post that's already been loaded
   *
   * @param posts The posts that have already been loaded, in chronological order
   */
  async get(posts: PostItem[], user?: number) {
    // Find the first real (non-chatbot) post
    const last = posts.reverse().find(post => post.id > 0);
    posts.reverse(); // Restore original order
    let since;
    if (last) {
      since = last.id;
    }

    let url = "/posts";
    if (user) {
      url = `/messages/${user}`;
    }
    if (since) {
      url += `?since=${since}`;
    }

    try {
      const json = await withAlert(
        () => api.fetchJson(url),
        () => "Failed to load posts"
      );

      return json.posts;
    } catch (error) {
      return [];
    }
  },

  /**
   * Sends a new post or private message
   */
  send(post: string, recipient?: number): Promise<boolean> {
    return withAlert(async () => {
      // Confirm before sending overly long posts
      if (post.length > MAX_POST_LENGTH) {
        const message = `This post is too long (${post.length}/${MAX_POST_LENGTH} characters) and will be truncated. Do you want to send it anyway?`;
        if (!window.confirm(message)) {
          return false;
        }
      }

      await websocket.send({
        type: recipient ? "private" : "post",
        post,
        recipient
      });

      return true;
    }, "Post failed to send");
  },

  /**
   * Deletes a post (moderator+ action)
   */
  delete(id: number) {
    return withAlert(() => {
      return api.fetchJson(`/posts/${id}`, {method: "DELETE"});
    });
  },

  /**
   * Votes to chatmine a post
   */
  chatmine(id: number) {
    return withAlert(async () => {
      await api.fetchJson(`/posts/chatmine/${id}`, {method: "POST"});

      voteListeners.forEach(callback => callback(id));
    });
  },

  /**
   * Returns recent posts from the chatmine
   */
  getChatmine() {
    return withAlert(() => {
      return api.fetchJson("/posts/chatmine");
    });
  },

  /**
   * Returns archived posts
   */
  getArchive(before?: number) {
    return withAlert(async () => {
      let url = "/posts/archive";
      if (before) {
        url += `?before=${before}`;
      }

      return api.fetchJson(url);
    });
  },

  /**
   * Merges newly-loaded posts into the existing list of posts, removing any duplicates
   */
  merge(old: PostItem[], loaded: PostItem[]) {
    const oldIds = old.map(p => p.id);
    const newAndUnique = loaded.filter(p => oldIds.indexOf(p.id) === -1);

    return old.concat(newAndUnique);
  },

  /**
   * Sets up listeners for voting to chatmine
   */
  subscribeVote,
  unsubscribeVote
};
