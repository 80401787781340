import * as React from "react";
import ImageDrop from "../../common/ImageDrop";
import {EmoteConsumer, EmoteProvider} from "../../emotes/EmoteContext";
import emotes from "../../emotes/emotes";
import ExampleEmote from "../../emotes/ExampleEmote";
import RoleGate, {roles} from "../../router/RoleGate";
import helpers from "../../utils/form-helpers";
import "./Emotes.css";

interface EmotesState {
  name: string;
  search: string;
  file?: File;
}

class Emotes extends React.Component<{}, EmotesState> {
  handleChange = helpers.handleChange.bind(this);

  constructor(props: {}) {
    super(props);

    this.state = {
      search: "",
      name: ""
    };

    this.onDrop = this.onDrop.bind(this);
    this.upload = this.upload.bind(this);
  }

  onDrop(file: File) {
    this.setState({file});
  }

  canUpload() {
    return this.state.name.length > 0 && this.state.file;
  }

  async upload() {
    const uploaded = await emotes.create(this.state.file, this.state.name);
    if (uploaded) {
      this.setState({name: "", file: undefined});
    }
  }

  renderAllEmotes(allEmotes: {[name: string]: string}) {
    const filtered: string[] = [];
    for (const emote in allEmotes) {
      if (!allEmotes.hasOwnProperty(emote)) {
        continue;
      }

      if (this.state.search && emote.search(this.state.search) === -1) {
        continue;
      }

      filtered.push(emote);
    }

    filtered.sort();

    return filtered.map(emote => <ExampleEmote emote={emote} key={emote} />);
  }

  render() {
    const {name, file} = this.state;
    return (
      <EmoteProvider>
        <RoleGate role={roles.admin}>
          <div className="Emotes">
            <h3>Add New Emote</h3>
            <input
              name="name"
              type="text"
              placeholder="Name"
              onChange={this.handleChange}
            />
            <ImageDrop onDrop={this.onDrop} />
            {file && !name && (
              <p className="name-missing">Choose a name to continue</p>
            )}
            {name && <p>:{name}:</p>}
            {this.canUpload() && <button onClick={this.upload}>Upload</button>}
          </div>
        </RoleGate>

        <div>
          <h3>Emote Library</h3>
          <div>
            <input
              name="search"
              type="text"
              placeholder="Search"
              onChange={this.handleChange}
            />
          </div>
          <EmoteConsumer>
            {allEmotes => this.renderAllEmotes(allEmotes)}
          </EmoteConsumer>
        </div>
      </EmoteProvider>
    );
  }
}

export default Emotes;
