import * as React from "react";
import {Router} from "react-router-dom";
import alerts from "../alerts/alerts";
import auth from "../auth/auth";
import {UserProvider} from "../auth/UserContext";
import {SettingsProvider} from "../common/SettingsContext";
import history from "../router/history";
import api from "../services/api";
import "./App.css";
import Header from "./Header";
import MainContainer from "./MainContainer";

const App = () => {
  // clear alerts when location changes
  history.listen(alerts.clear);

  // automatically log out when token expires
  api.onExpiredToken.push(auth.logout.bind(auth));

  return (
    <SettingsProvider>
      <UserProvider>
        <Router history={history}>
          <div className="App">
            <Header />
            <MainContainer />
          </div>
        </Router>
      </UserProvider>
    </SettingsProvider>
  );
};

export default App;
