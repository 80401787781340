import * as React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import auth from "../auth/auth";

interface PrivateRouteProps extends RouteProps {
  component: any;
  passive?: boolean;
}

class PrivateRoute extends React.Component<PrivateRouteProps> {
  renderChild = (props: RouteProps) => {
    const {component: Child, passive = false} = this.props;

    return auth.isAuthenticated() ? (
      <Child {...props} />
    ) : (
      passive || (
        <Redirect to={{pathname: "/login", state: {from: props.location}}} />
      )
    );
  };

  render() {
    const {component, passive, ...rest} = this.props;

    return <Route {...rest} render={this.renderChild} />;
  }
}

export default PrivateRoute;
