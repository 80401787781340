import * as React from "react";
import {FaTrophy} from "react-icons/fa";
import Markdown from "react-markdown";
import {Link} from "react-router-dom";
import remarkDisableTokenizers from "remark-disable-tokenizers";
import TextRenderer from "../emotes/TextRenderer";
import User, {UserItem} from "../users/User";
import {Ignore} from "../users/users";
import Code from "./Code";
import {IgnoresConsumer} from "./IgnoresContext";
import ImageRenderer from "./ImageRenderer";
import LinkRenderer from "./LinkRenderer";
import "./Post.css";
import PostActions from "./PostActions";

export interface PostItem {
  id: number;
  created: string;
  poster: string;
  poster_id: number;
  post: string;
  chatmined?: boolean;
  chatmine_pending?: boolean;
  voted?: boolean;
  isPrivate?: boolean;
}

interface PostProps {
  post: PostItem;
  readOnly?: boolean;
  isPrivate?: boolean;
}

const markdownTypes: Markdown.NodeType[] = [
  "root",
  "text",
  "paragraph",
  "break",
  "emphasis",
  "strong",
  "blockquote",
  "delete",
  "link",
  "image",
  "inlineCode",
  "code"
];

const disabledTokenizers = {
  inline: ["html", "reference", "break"],
  block: [
    "indentedCode",
    "atxHeading",
    "thematicBreak",
    "list",
    "setextHeading",
    "html",
    "footnote",
    "definition",
    "table"
  ]
};

function renderChatmined(post: PostItem) {
  if (post.chatmined) {
    return <FaTrophy className="chatmined" color="gold" title="Chatmined" />;
  } else if (post.chatmine_pending) {
    return (
      <FaTrophy className="chatmined" color="gray" title="Chatmine Pending" />
    );
  }

  return null;
}

function inline(post: PostItem, ignores: Ignore[]) {
  return !ignores.some(
    ignore => ignore.id === post.poster_id && ignore.images_only
  );
}

const Post: React.FunctionComponent<PostProps> = React.memo(
  ({post, readOnly, isPrivate}) => {
    const date = new Date(post.created);
    const fullDate = date.toLocaleString();
    const time = date.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit"
    });

    const user: UserItem = {
      id: post.poster_id,
      name: post.poster,
      role: "member" // dummy value
    };

    const showingPrivateInPublic = post.isPrivate && !isPrivate;

    return (
      <div className="Post">
        <div className="header">
          <span className="poster">
            <User user={user} />
          </span>
          {renderChatmined(post)}
          <span className="timestamp" title={fullDate}>
            {readOnly ? fullDate : time}
          </span>
          {showingPrivateInPublic && (
            <span>
              {" "}
              (<Link to={`/chat/private/${user.id}`}>privately</Link>){" "}
            </span>
          )}
          {readOnly || showingPrivateInPublic || (
            <PostActions post={post} isPrivate={isPrivate} />
          )}
        </div>

        <IgnoresConsumer>
          {ignores => (
            <Markdown
              className="post"
              source={post.post}
              linkTarget="_blank"
              renderers={{
                code: Code,
                text: TextRenderer,
                link: LinkRenderer,
                image: ImageRenderer(inline(post, ignores))
              }}
              allowedTypes={markdownTypes}
              unwrapDisallowed
              plugins={[[remarkDisableTokenizers, disabledTokenizers]]}
            />
          )}
        </IgnoresConsumer>
      </div>
    );
  }
);

export default Post;
