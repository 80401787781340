import alerts from '../alerts/alerts';

interface RequestOptions extends RequestInit {
  body?: any;
  noJson?: boolean; // if true, don't auto-convert request body to JSON
  noReload?: boolean; // if true, don't reload the page after a 401 response
}

interface RequestError extends Error {
  status: number;
  body: ReadableStream | null;
}

/**
 * Performs an asynchronous operation, sending an alert on error
 *
 * The message can be customized with a string or callback, and defaults to the error message
 */
export async function withAlert<T>(
  func: () => Promise<T>,
  msg?: string | ((err: any) => string)
) {
  try {
    return await func();
  } catch (err) {
    let message = err.message;
    if (msg) {
      message = (msg instanceof Function) ? msg(err) : msg;
    }
    alerts.sendMsg(message);

    throw err;
  }
}

export default {
  // handle expired tokens via observer to avoid circular dependency with auth service
  onExpiredToken: [] as Array<() => void>,
  async fetch(input: Request | string, options: RequestOptions = {}) {
    options.headers = options.headers || new Headers();

    // add jwt auth header if available
    const headers = options.headers as Headers;
    if (!headers.has("Authorization")) {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    }

    // auto-encode json bodies
    if (typeof options.body === "object" && !options.noJson) {
      headers.set("Content-Type", "application/json");
      options.body = JSON.stringify(options.body);
    }

    // add prefix to url
    if (typeof input === "string") {
      input = "/api" + input;
    }

    const response = await fetch(input, options);

    if (response.status === 401) {
      this.onExpiredToken.forEach(callback => callback());
    }

    return response;
  },
  async fetchJson(input: Request | string, options: RequestOptions = {}) {
    const response = await this.fetch(input, options);

    const json = await this.decode(response);

    // reload if token is missing, will need to authenticate again
    if (
      response.status === 401 &&
      !options.noReload &&
      !localStorage.getItem("token")
    ) {
      window.location.reload();
    }

    // throw error for non-2XX HTTP statuses
    if (!response.status.toString().match(/^2/)) {
      const error = Error(json.message || "Request failed") as RequestError;
      error.status = response.status;
      error.body = response.body;

      throw error;
    }

    return json;
  },
  async decode(response: Response) {
    try {
      return await response.json();
    } catch (err) {
      err.status = response.status;
      err.body = response.body;
      throw err;
    }
  }
};
