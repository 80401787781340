import * as React from "react";
import "./SidebarContainer.css";

interface SidebarContainerProps {
  header: string;
}

const SidebarContainer: React.FunctionComponent<SidebarContainerProps> = ({
  children,
  header
}) => (
  <div className="SidebarContainer">
    <div className="header">{header}</div>
    <div className="content">{children}</div>
  </div>
);

export default SidebarContainer;
