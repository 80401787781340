import * as React from "react";
import {Ignore} from "../users/users";
import {IgnoresConsumer} from "./IgnoresContext";
import Post, {PostItem} from "./Post";

interface PostListProps {
  posts: PostItem[];
  readOnly?: boolean;
  isPrivate?: boolean;
}

function nonIgnoredPosts(posts: PostItem[], ignores: Ignore[], isPrivate?: boolean) {
  if (isPrivate) {
    // Don't hide private messages from ignored users
    return posts;
  }

  const ignoredIds = ignores
    .filter(ignore => !ignore.images_only)
    .map(ignore => ignore.id);

  return posts.filter(post => !ignoredIds.includes(post.poster_id));
}

const PostList: React.FunctionComponent<PostListProps> = ({
  posts,
  readOnly,
  isPrivate
}) => (
  <div className="PostList">
    <IgnoresConsumer>
      {ignores =>
        nonIgnoredPosts(posts, ignores, isPrivate).map(post => (
          <Post
            post={post}
            key={post.id}
            readOnly={readOnly}
            isPrivate={isPrivate}
          />
        ))
      }
    </IgnoresConsumer>
  </div>
);

export default PostList;
