import * as React from "react";
import {FaBan, FaQuoteLeft, FaRegStar, FaStar} from "react-icons/fa";
import RoleGate, {roles} from "../router/RoleGate";
import errors from "../utils/errors";
import {PostItem} from "./Post";
import postEvents from "./post-events";
import "./PostActions.css";
import posts from "./posts";

interface PostActionsProps {
  post: PostItem;
  isPrivate?: boolean;
}

const onDelete = ({id}: PostItem) => () => {
  if (!window.confirm("Are you sure you want to delete that post?")) {
    return;
  }

  errors.ignore(() => {
    return posts.delete(id);
  });
};

const onQuote = (post: PostItem) => () => {
  postEvents.insertPost(post);
};

const onStar = ({id}: PostItem) => () => {
  errors.ignore(() => {
    return posts.chatmine(id);
  });
};

const renderStar = (post: PostItem) => {
  if (post.voted) {
    return <FaStar className="star voted" title="Voted to Chatmine" />;
  }

  return <FaRegStar className="star" title="Chatmine" onClick={onStar(post)} />;
};

const PostActions: React.FunctionComponent<PostActionsProps> = ({
  post,
  isPrivate
}) => {
  const isRealPost = !isPrivate && post.id > 0;

  return (
    <span className="PostActions">
      {isRealPost && renderStar(post)}
      <FaQuoteLeft title="Quote Post" onClick={onQuote(post)} />
      {isRealPost && (
        <RoleGate role={roles.moderator}>
          <FaBan
            className="delete"
            title="Delete Post"
            onClick={onDelete(post)}
          />
        </RoleGate>
      )}
    </span>
  );
};

export default PostActions;
