import * as React from "react";
import auth from "./auth";
import {UserItem} from "../users/User";

const {Provider, Consumer} = React.createContext<UserItem | undefined>(
  undefined
);

interface UserProviderState {
  user?: UserItem;
}

export class UserProvider extends React.Component<{}, UserProviderState> {
  state = {user: auth.getUser() || undefined};

  componentDidMount() {
    auth.onAuthChange(() => this.setState({user: auth.getUser() || undefined}));
  }

  render() {
    return <Provider value={this.state.user}>{this.props.children}</Provider>;
  }
}

export {Consumer as UserConsumer};
