import * as React from "react";
import Emote from "./Emote";
import parse from "./emote-parser";

interface TextRendererProps {
  value: string;
  nodeKey: string;
}

const TextRenderer: React.FunctionComponent<TextRendererProps> = ({
  value,
  nodeKey
}) => {
  const chunks = parse(value);

  const elements = chunks.map((chunk, i) => {
    const childKey = `${nodeKey}-${i}`;

    if (!chunk.emote) {
      return <span key={childKey}>{chunk.text}</span>;
    }

    return <Emote key={childKey} emote={chunk.emote} />;
  });

  return <span key={nodeKey}>{elements}</span>;
};

export default TextRenderer;
