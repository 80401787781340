import * as React from "react";
import {Link} from "react-router-dom";

const NotFound = () => (
  <div>
    <h2>Not Found</h2>
    <p>
      Whatever you were looking for isn't here, click <Link to="/">here</Link>{" "}
      to return home
    </p>
  </div>
);

export default NotFound;
