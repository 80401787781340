import * as React from "react";
import {NavLink} from "react-router-dom";
import "./SettingsCategory.css";

interface SettingsCategoryProps {
  to: string;
  name: string;
}

const SettingsCategory: React.FunctionComponent<SettingsCategoryProps> = ({
  to,
  name
}) => (
  <NavLink className="SettingsCategory" to={to}>
    {name}
  </NavLink>
);

export default SettingsCategory;
