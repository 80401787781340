import * as React from "react";
import {
  FaBan,
  FaComments,
  FaEnvelopeOpen,
  FaLongArrowAltLeft,
  FaUserCheck
} from "react-icons/fa";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import auth from "../../../auth/auth";
import BanExpiration from "../../../common/BanExpiration";
import LoadIndicator from "../../../common/LoadIndicator";
import Panel from "../../../common/Panel";
import RoleGate, {roles} from "../../../router/RoleGate";
import {UserItem} from "../../../users/User";
import {default as userApi, default as users} from "../../../users/users";
import errors from "../../../utils/errors";
import helpers from "../../../utils/form-helpers";
import BanForm from "./BanForm";
import IgnoreForm from "./IgnoreForm";
import RoleForm from "./RoleForm";
import UserNotFound from "./UserNotFound";
import "./UserProfile.css";

interface RouteParams {
  id: string;
}

interface UserProfileState {
  notFound: boolean;
  loading: boolean;
  ignore: string;
  user?: UserItem;
  banMinutes?: string;
}

class UserProfile extends React.Component<
  RouteComponentProps<RouteParams>,
  UserProfileState
> {
  handleChange = helpers.handleChange.bind(this);
  state: UserProfileState = {
    notFound: false,
    loading: true,
    ignore: "unignored"
  };

  componentDidMount() {
    this.loadUser();
  }

  loadUser = async () => {
    const userId = parseInt(this.props.match.params.id, 10);
    const user = await users.getOne(userId).catch(e => null);
    if (user) {
      const ignore = await users.getIgnoreStatus(userId);

      this.setState({user, ignore, loading: false});
    } else {
      this.setState({notFound: true, loading: false});
    }
  };

  onBan = (minutes?: number) =>
    errors.ignore(async () => {
      const {user} = this.state;
      if (user) {
        await userApi.ban(user, minutes);
        this.loadUser();
      }
    });

  unban = () =>
    errors.ignore(async () => {
      const {user} = this.state;
      if (user) {
        await userApi.unban(user);
        this.loadUser();
      }
    });

  ignore = (status: string) =>
    errors.ignore(async () => {
      const {user} = this.state;
      if (user) {
        await users.ignore(user.id, status);

        this.setState({ignore: status});
      }
    });

  setRole = (role: string) =>
    errors.ignore(async () => {
      const {user} = this.state;
      if (user) {
        await users.setRole(user, role);
        this.loadUser();
      }
    });

  render() {
    const {user, ignore, loading, notFound} = this.state;

    if (loading) {
      return <LoadIndicator />;
    }

    if (notFound || !user) {
      return <UserNotFound />;
    }

    const {id, name, email, role, bannedUntil} = user;
    const currentUser = auth.getUser();
    const viewingSelf = currentUser && currentUser.id === id;

    return (
      <div className="UserProfile">
        <Link to="/settings/users" className="back">
          <FaLongArrowAltLeft /> All Users
        </Link>

        <div className="panels">
          <Panel>
            <h2>{name}</h2>
            <ul>
              <li>
                <FaUserCheck /> {role}
              </li>
              <li>
                <FaComments /> <Link to={`/chat/private/${id}`}>Message</Link>
              </li>
              {email && (
                <li>
                  <FaEnvelopeOpen /> <a href={`mailto:${email}`}>{email}</a>
                </li>
              )}
              {bannedUntil && (
                <li>
                  <FaBan /> <BanExpiration expires={bannedUntil} />
                </li>
              )}
            </ul>
          </Panel>

          {viewingSelf || (
            <Panel>
              <h2>Ignore</h2>
              <IgnoreForm status={ignore} onChange={this.ignore} />
            </Panel>
          )}

          {viewingSelf || (
            <RoleGate role={roles.moderator}>
              <Panel>
                <h2>Role</h2>
                <RoleForm role={role} onChange={this.setRole} />
              </Panel>

              <Panel>
                <h2>Ban</h2>
                <BanForm
                  banned={!!bannedUntil}
                  onBan={this.onBan}
                  onUnban={this.unban}
                />
              </Panel>
            </RoleGate>
          )}
        </div>
      </div>
    );
  }
}

export default UserProfile;
