import * as React from "react";
import {Redirect, Route, Switch} from "react-router";
import CloseButton from "../common/CloseButton";
import GuestGate from "../common/GuestGate";
import panes from "./panes/config";
import Users from "./panes/users/Users";
import "./SettingsContainer.css";
import SettingsNav from "./SettingsNav";

const redirect = <Redirect to="/settings/profile" />;

const SettingsContainer: React.FunctionComponent = () => (
  <div className="SettingsContainer">
    <CloseButton />
    <SettingsNav />
    <div className="settings-pane">
      <Switch>
        {panes.map(([_, path, Pane]) => (
          <Route path={path} component={Pane} key={path} />
        ))}
        <Route path="/settings/users">
          <GuestGate guest={redirect}>
            <Users />
          </GuestGate>
        </Route>
        {redirect}
      </Switch>
    </div>
  </div>
);

export default SettingsContainer;
