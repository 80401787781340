import alerts from "../alerts/alerts";
import history from "../router/history";
import api, {withAlert} from "../services/api";
import {UserItem} from "../users/User";

type AuthCallback = () => void;

const roles = ["user", "member", "moderator", "admin"];

const listeners: AuthCallback[] = [];

function updateListeners() {
  listeners.forEach(listener => listener());
}

function updateUser(user: object, token: string) {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);

  updateListeners();
}

export default {
  /**
   * Fetches the captcha key from the server configuration
   */
  async getCaptchaKey() {
    const json = await api.fetchJson("/auth/captcha-key");

    return json.key;
  },

  /**
   * Registers a new user
   */
  async register(
    email: string,
    name: string,
    password: string,
    captcha: string
  ) {
    alerts.clear();

    return withAlert(async () => {
      await api.fetchJson("/auth/register", {
        method: "POST",
        body: {email, name, password, "g-recaptcha-response": captcha}
      });

      return this.login(email, password);
    });
  },

  /**
   * Logs in an existing user
   */
  async login(email: string, password: string) {
    alerts.clear();

    return withAlert(
      async () => {
        const data = await api.fetchJson("/auth/login", {
          method: "POST",
          body: {email, password},
          noReload: true
        });

        updateUser(data.user, data.token);
        history.push("/");

        return data;
      },
      err => (err.status === 401 ? "Invalid email/password" : err.message)
    );
  },

  /**
   * Updates a user's display name
   */
  async updateName(name: string) {
    return withAlert(async () => {
      const data = await api.fetchJson("/auth/name", {
        method: "PUT",
        body: {name}
      });

      updateUser(data.user, data.token);

      alerts.send({message: "Display name changed", clear: true});
    });
  },

  /**
   * Updates a user's email address
   */
  async updateEmail(email: string) {
    return withAlert(async () => {
      const data = await api.fetchJson("/auth/email", {
        method: "PUT",
        body: {email}
      });

      updateUser(data.user, data.token);

      alerts.send({message: "Email address changed", clear: true});
    });
  },

  /**
   * Updates a user's password
   */
  async updatePassword(currentPassword: string, newPassword: string) {
    return withAlert(
      async () => {
        const user = this.getUser();
        if (!user) {
          return;
        }

        const {email} = user;
        if (!email) {
          return;
        }

        await api.fetchJson("/auth/password", {
          method: "PUT",
          body: {email, password: currentPassword, newPassword},
          noReload: true
        });

        alerts.send({message: "Password changed", clear: true});
      },
      err => (err.status === 401 ? "Invalid current password" : err.message)
    );
  },

  /**
   * Requests access to chat
   */
  async requestAccess(message: string) {
    return withAlert(() =>
      api.fetchJson("/auth/access-request", {method: "POST", body: {message}})
    );
  },

  /**
   * Checks the status of user's chat access request
   */
  async checkAccess() {
    return withAlert(async () => {
      const {accepted, user, token} = await api.fetchJson(
        "/auth/access-request"
      );

      if (accepted) {
        updateUser(user, token);
      }
    });
  },

  /**
   * Logs a user out
   */
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    history.push("/login");

    updateListeners();
  },

  /**
   * Returns whether a user is logged in
   */
  isAuthenticated() {
    return !!localStorage.getItem("token");
  },

  /**
   * Returns the logged-in user if there is one, or null
   */
  getUser(): UserItem | null {
    const json = localStorage.getItem("user");
    if (!json) {
      return null;
    }

    return JSON.parse(json);
  },

  /**
   * Returns whether a user is authorized to see or do something based on their role
   */
  isAuthorized(requiredRole: string, userRole?: string) {
    // if user has no role defined, only give user-level access
    if (!userRole) {
      return requiredRole === roles[0];
    }

    // user role must be at or above the required role's level
    return roles.indexOf(userRole) >= roles.indexOf(requiredRole);
  },

  /**
   * Registers a callback
   */
  onAuthChange(callback: AuthCallback) {
    listeners.push(callback);
  }
};
