import * as React from "react";
import emotes, {EmoteMap} from "./emotes";

const {Provider, Consumer} = React.createContext({});

interface EmoteProviderState {
  emotes: EmoteMap;
}

export class EmoteProvider extends React.Component<{}, EmoteProviderState> {
  state: EmoteProviderState = {emotes: {}};

  async componentDidMount() {
    const emoteConfig = await emotes.get();

    this.setState({emotes: emoteConfig});
  }

  render() {
    return <Provider value={this.state.emotes}>{this.props.children}</Provider>;
  }
}

export {Consumer as EmoteConsumer};
