import * as React from "react";
import auth from "../../auth/auth";
import helpers from "../../utils/form-helpers";
import "./Profile.css";
import errors from "../../utils/errors";

interface ProfileState {
  originalName: string;
  name: string;
  originalEmail: string;
  email: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

class Profile extends React.Component<{}, ProfileState> {
  handleChange = helpers.handleChange.bind(this);

  constructor(props: object) {
    super(props);

    this.state = {
      originalName: "",
      name: "",
      originalEmail: "",
      email: "",
      password: "",
      newPassword: "",
      confirmNewPassword: ""
    };
  }

  componentDidMount() {
    const user = auth.getUser();
    if (!user) {
      return;
    }

    const {name, email} = user;
    if (email) {
      this.setState({originalName: name, name, originalEmail: email, email});
    }
  }

  updateName = async (e: React.FormEvent) =>
    errors.ignore(async () => {
      e.preventDefault();

      const {name} = this.state;
      await auth.updateName(name);
      this.setState({name, originalName: name});
    });

  updateEmail = async (e: React.FormEvent) =>
    errors.ignore(async () => {
      e.preventDefault();

      const {email} = this.state;
      await auth.updateEmail(email);
      this.setState({email, originalName: email});
    });

  updatePassword = async (e: React.FormEvent) =>
    errors.ignore(async () => {
      e.preventDefault();

      const {password, newPassword} = this.state;
      await auth.updatePassword(password, newPassword);
      this.setState({password: "", newPassword: "", confirmNewPassword: ""});
    });

  render() {
    const {
      originalName,
      name,
      originalEmail,
      email,
      password,
      newPassword,
      confirmNewPassword
    } = this.state;

    const invalidPasswordConfirm: boolean =
      !!confirmNewPassword && newPassword !== confirmNewPassword;
    const invalidPassword: boolean =
      !password || !newPassword || invalidPasswordConfirm;

    return (
      <div className="Profile">
        <h3>Profile</h3>

        <form onSubmit={this.updateName}>
          <label>Display Name</label>
          <input
            type="text"
            name="name"
            placeholder="Display Name"
            value={name}
            onChange={this.handleChange}
            required
          />
          <button disabled={!name || originalName === name}>Change</button>
        </form>

        <form onSubmit={this.updateEmail}>
          <label>Email</label>
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={this.handleChange}
            required
          />
          <button disabled={!email || originalEmail === email}>Change</button>
        </form>

        <form onSubmit={this.updatePassword}>
          <div>
            <label>Current Password</label>
            <input
              type="password"
              name="password"
              placeholder="Current Password"
              value={password}
              onChange={this.handleChange}
              required
            />
          </div>

          <div>
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              placeholder="New Password"
              value={newPassword}
              onChange={this.handleChange}
              required
            />
          </div>

          <div>
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirmNewPassword"
              placeholder="Confirm Password"
              value={confirmNewPassword}
              onChange={this.handleChange}
              className={invalidPasswordConfirm ? "invalid" : ""}
              required
            />
            <button disabled={invalidPassword}>Change</button>
          </div>
        </form>
      </div>
    );
  }
}

export default Profile;
