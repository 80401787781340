import * as React from "react";
import SidebarContainer from "../chat/SidebarContainer";
import "./InsertSidebar.css";
import postEvents from "./post-events";

const placeholder = "text";

/**
 * Wrapper to prevent clicks from stealing focus from post input
 */
const preventingDefault = (fn: () => void) => (evt: React.MouseEvent<any>) => {
  evt.preventDefault();
  fn();
};

const italic = preventingDefault(() =>
  postEvents.wrapSelection(placeholder)(text => `*${text}*`)
);

const bold = preventingDefault(() =>
  postEvents.wrapSelection(placeholder)(text => `**${text}**`)
);

const blockquote = preventingDefault(() =>
  postEvents.wrapSelection(placeholder)(text => `\n> ${text}`)
);

const strikethrough = preventingDefault(() =>
  postEvents.wrapSelection(placeholder)(text => `~~${text}~~`)
);

const code = preventingDefault(() =>
  postEvents.wrapSelection('const foo = "bar";')(
    text => `\n\`\`\`javascript\n${text}\n\`\`\`\n`
  )
);

const image = preventingDefault(() =>
  postEvents.wrapSelection(`//${window.location.host}/favicon.ico`)(
    url => `![](${url})`
  )
);

/**
 * Uses mousedown event instead of click because focus is lost by the time click handlers are called
 */
const InsertSidebar: React.FunctionComponent = () => (
  <SidebarContainer header="Insert">
    <div className="InsertSidebar">
      <p>
        <em onMouseDown={italic}>Italics</em>
      </p>
      <p>
        <strong onMouseDown={bold}>Bold</strong>
      </p>
      <p className="strike" onMouseDown={strikethrough}>
        Strike
      </p>
      <p onMouseDown={image}>Image</p>
      <p onMouseDown={blockquote}>Quote</p>
      <p onMouseDown={code}>Code</p>
    </div>
  </SidebarContainer>
);

export default InsertSidebar;
