import * as React from "react";
import auth from "../auth/auth";
import {UserConsumer} from "../auth/UserContext";

interface RoleGateProps {
  role: string;
  reverse?: boolean;
}

const RoleGate: React.FunctionComponent<RoleGateProps> = ({
  role,
  reverse,
  children
}) => (
  <UserConsumer>
    {user =>
      !!reverse !== auth.isAuthorized(role, user && user.role) && children
    }
  </UserConsumer>
);

export default RoleGate;

export const roles = {
  admin: "admin",
  moderator: "moderator",
  member: "member",
  user: "user"
};
