export default {
  /**
   * Handles errors by doing nothing
   *
   * Useful in cases where no additional action is necessary on failure
   * e.g. if another layer sends an alert but re-throws
   */
  async ignore<T>(func: () => Promise<T>) {
    try {
      await func();
    } catch(err) {
      // no-op
    }
  }
};
