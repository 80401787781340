import * as React from "react";
import {roles} from "../router/RoleGate";
import UserSettingsConsumer from "../chat/UserSettingsConsumer";

interface Props {
  children: React.ReactNode;
  guest?: React.ReactNode;
  spinner?: boolean;
}

const GuestGate: React.FunctionComponent<Props> = ({
  children,
  guest,
  spinner
}) => (
  <UserSettingsConsumer spinner={spinner}>
    {(settings, user) => {
      if (!settings.allow_guests_in_chat && user.role === roles.user) {
        return guest;
      }

      return children;
    }}
  </UserSettingsConsumer>
);

export default GuestGate;
