import * as React from "react";
import {Route, Switch} from "react-router";
import AlertList from "../alerts/AlertList";
import ArchiveContainer from "../archive/ArchiveContainer";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ChatWrapper from "../chat/ChatWrapper";
import ChatmineContainer from "../chatmine/ChatmineContainer";
import GuestGate from "../common/GuestGate";
import PrivateRoute from "../router/PrivateRoute";
import SettingsContainer from "../settings/SettingsContainer";
import "./MainContainer.css";
import NotFound from "./NotFound";

const MainContainer: React.FunctionComponent = () => (
  <div className="MainContainer">
    <AlertList />
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <PrivateRoute exact path="/" component={ChatWrapper} />
      <PrivateRoute path="/chat" component={ChatWrapper} />
      <PrivateRoute path="/settings" component={SettingsContainer} />
      <GuestGate guest={<NotFound />}>
        <PrivateRoute path="/chatmine" component={ChatmineContainer} />
        <PrivateRoute path="/archive" component={ArchiveContainer} />
      </GuestGate>
      <NotFound />
    </Switch>
  </div>
);

export default MainContainer;
