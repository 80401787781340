import * as React from "react";
import User, {UserItem} from "./User";

interface UserListProps {
  users: UserItem[];
}

const OnlineUsers: React.FunctionComponent<UserListProps> = ({users}) => (
  <div className="OnlineUsers">
    {users.map(user => (
      <User user={user} key={user.id} />
    ))}
  </div>
);

export default OnlineUsers;
