import * as React from "react";
import {RouteComponentProps} from "react-router";
import LoadIndicator from "../common/LoadIndicator";
import PostContainer from "../posts/PostContainer";
import history from "../router/history";
import {UserItem} from "../users/User";
import users from "../users/users";

interface RouteParams {
  user: string;
}

interface State {
  user?: UserItem;
}

class UserMessageContainer extends React.Component<
  RouteComponentProps<RouteParams>,
  State
> {
  state: State = {};

  async componentDidMount() {
    const userId = parseInt(this.props.match.params.user, 10);
    try {
      const user = await users.getOne(userId);
      if (user) {
        this.setState({user});
      } else {
        history.push("/");
      }
    } catch (err) {
      history.push("/");
    }
  }

  render() {
    const {user} = this.state;
    if (user) {
      return <PostContainer user={user} closeable />;
    }

    return <LoadIndicator />;
  }
}

export default UserMessageContainer;
