import * as React from "react";
import {FaMinusSquare} from "react-icons/fa";
import {Redirect, Route, Switch} from "react-router";
import {Link} from "react-router-dom";
import InsertSidebar from "../posts/InsertSidebar";
import OnlineUsersContainer from "../users/OnlineUsersContainer";
import "./ChatSidebar.css";

const ChatSidebar: React.FunctionComponent = () => (
  <div className="ChatSidebar">
    <div className="collapse">
      <Link to="/chat">
        <FaMinusSquare />
      </Link>
    </div>
    <Switch>
      <Route path="/chat/users" component={OnlineUsersContainer} />
      <Route path="/chat/insert" component={InsertSidebar} />
      <Redirect to="/chat" />
    </Switch>
  </div>
);

export default ChatSidebar;
