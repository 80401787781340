import * as React from "react";
import TooltipTrigger from "react-popper-tooltip";
import "./User.css";
import UserActions from "./UserActions";
import {TooltipArg} from "react-popper-tooltip/dist/types";

export interface UserItem {
  id: number;
  name: string;
  role: string;
  email?: string;
  bannedUntil?: string;
}

interface UserProps {
  user: UserItem;
}

const User: React.FunctionComponent<UserProps> = ({user}) => {
  function renderTooltip(tooltip: TooltipArg) {
    return <UserActions {...tooltip} user={user} />;
  }

  return (
    <TooltipTrigger placement="bottom" trigger="click" tooltip={renderTooltip}>
      {({getTriggerProps, triggerRef}) => (
        <div {...getTriggerProps({ref: triggerRef, className: "User"})}>
          <span className="name">{user.name}</span>
        </div>
      )}
    </TooltipTrigger>
  );
};

export default User;
