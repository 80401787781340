import * as React from "react";
import auth from "../auth/auth";
import errors from "../utils/errors";
import "./RequestAccess.css";

function renderForm(
  message: string,
  setMessage: (message: string) => void,
  onClick: () => void
) {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setMessage(evt.target.value);

  return (
    <React.Fragment>
      <p>
        Access to chat is restricted for new members, but you can request access
        from here
      </p>

      <label>A brief hello or note about yourself</label>
      <input
        type="text"
        value={message}
        onChange={handleChange}
        placeholder="Racial greetings, brother."
      />

      <div>
        <button onClick={onClick}>Request Access</button>
      </div>
    </React.Fragment>
  );
}

const RequestAccess = () => {
  const [requested, setRequested] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");

  React.useEffect(() => {
    // Check for access every 5 seconds
    const interval = setInterval(() => {
      errors.ignore(auth.checkAccess);
    }, 5000);

    return () => clearInterval(interval);
  });

  const onClick = () =>
    errors.ignore(async () => {
      await auth.requestAccess(message);
      setRequested(true);
    });

  return (
    <div className="RequestAccess">
      <h1>Chat Restricted</h1>

      {requested || renderForm(message, setMessage, onClick)}

      {requested && (
        <p>
          Your request has been sent.
          <br />
          Stick around on this page and you'll join chat automatically if
          approved
        </p>
      )}
    </div>
  );
};

export default RequestAccess;
