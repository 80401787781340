import * as React from "react";
import {Route, Switch} from "react-router";
import {EmoteProvider} from "../emotes/EmoteContext";
import {IgnoresProvider} from "../posts/IgnoresContext";
import PostContainer from "../posts/PostContainer";
import UserMessageContainer from "../messages/UserMessageContainer";
import websocket from "../services/websocket";
import "./ChatContainer.css";
import ChatSidebar from "./ChatSidebar";

class ChatContainer extends React.Component {
  async componentDidMount() {
    websocket.connect();
  }

  componentWillUnmount() {
    if (websocket.isConnected()) {
      websocket.disconnect();
    }
  }

  render() {
    return (
      <EmoteProvider>
        <IgnoresProvider>
          <div className="ChatContainer">
            <Switch>
              <Route
                path="/chat/private/:user"
                component={UserMessageContainer}
              />
              <PostContainer />
            </Switch>
            <Switch>
              {/* Hide sidebar for private chats */}
              <Route path="/chat/private" />
              <Route path="/chat/:sidebar" component={ChatSidebar} />
            </Switch>
          </div>
        </IgnoresProvider>
      </EmoteProvider>
    );
  }
}

export default ChatContainer;
