import * as React from "react";
import Emote from "./Emote";
import "./ExampleEmote.css";

interface ExampleEmoteProps {
  emote: string;
}

const ExampleEmote: React.FunctionComponent<ExampleEmoteProps> = ({emote}) => (
  <div className="ExampleEmote">
    <Emote emote={emote} />
    <div className="name">:{emote}:</div>
  </div>
);

export default ExampleEmote;
