import * as React from "react";
import {FaPlus, FaUsers} from "react-icons/fa";
import "./SidebarNavigation.css";
import SidebarNavigationLink from "./SidebarNavigationLink";

const SidebarNavigation: React.FunctionComponent = () => (
  <div className="SidebarNavigation">
    <SidebarNavigationLink to="/chat/insert" icon={FaPlus} title="Insert" />
    <SidebarNavigationLink to="/chat/users" icon={FaUsers} title="User List" />
  </div>
);

export default SidebarNavigation;
