import * as React from "react";
import auth from "../auth/auth";
import SidebarContainer from "../chat/SidebarContainer";
import LoadIndicator from "../common/LoadIndicator";
import websocket from "../services/websocket";
import OnlineUsers from "./OnlineUsers";
import {UserItem} from "./User";
import userApi from "./users";
import errors from '../utils/errors';

interface OnlineUsersContainerState {
  users: UserItem[];
  loading: boolean;
}

interface ConnectMessage {
  type: "connect";
  user: UserItem;
}

interface DisconnectMessage {
  type: "disconnect";
  user: number;
}

class OnlineUsersContainer extends React.Component<
  {},
  OnlineUsersContainerState
> {
  state = {users: [], loading: true};

  async componentDidMount() {
    await this.loadUsers();

    websocket.onReconnect(this.loadUsers);
    websocket.onMessage(this.onMessage);
  }

  componentWillUnmount() {
    websocket.removeListeners(this.loadUsers, this.onMessage);
  }

  /**
   * Load all online users
   */
  loadUsers = () => errors.ignore(async () => {
    let users = await userApi.getOnline();

    // Ensure current user is included in list
    const user = auth.getUser();
    if (user) {
      users = userApi.add(users, user);
    }

    this.setState({users, loading: false});
  });

  onMessage = (message: ConnectMessage | DisconnectMessage) => {
    if (message.type === "connect") {
      this.setState(({users}) => ({users: userApi.add(users, message.user)}));
    }

    if (message.type === "disconnect") {
      this.setState(({users}) => ({
        users: userApi.remove(users, message.user)
      }));
    }
  };

  render() {
    const {users, loading} = this.state;

    return (
      <SidebarContainer header="Online Users">
        {loading && <LoadIndicator />}
        <OnlineUsers users={users} />
      </SidebarContainer>
    );
  }
}

export default OnlineUsersContainer;
