import * as React from "react";
import CloseButton from "../common/CloseButton";
import LoadIndicator from "../common/LoadIndicator";
import {EmoteProvider} from "../emotes/EmoteContext";
import {IgnoresProvider} from "../posts/IgnoresContext";
import {PostItem} from "../posts/Post";
import PostList from "../posts/PostList";
import postService from "../posts/posts";
import errors from "../utils/errors";
import "./ArchiveContainer.css";

interface ArchiveContainerState {
  posts: PostItem[];
  more: boolean;
  oldest?: number;
  loading: boolean;
}

class ArchiveContainer extends React.Component<{}, ArchiveContainerState> {
  state: ArchiveContainerState = {
    posts: [],
    more: false,
    loading: true
  };

  componentDidMount() {
    errors.ignore(async () => {
      const {posts, more, oldest} = await postService.getArchive();
      this.setState({posts, more, oldest, loading: false});
    });
  }

  loadMore = () =>
    errors.ignore(async () => {
      this.setState({loading: true});
      const {posts, more, oldest} = await postService.getArchive(
        this.state.oldest
      );

      this.setState(({posts: old}) => {
        const merged = postService.merge(old, posts);

        return {posts: merged, more, oldest, loading: false};
      });
    });

  render() {
    const {posts, more, loading} = this.state;

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    return (
      <IgnoresProvider>
        <EmoteProvider>
          <div className="ArchiveContainer">
            <h2>Archive</h2>
            <CloseButton />
            <PostList posts={posts} readOnly />
            {loading && <LoadIndicator />}
            {more && !loading && (
              <div className="more">
                <button onClick={this.loadMore}>Load More</button>
              </div>
            )}
          </div>
        </EmoteProvider>
      </IgnoresProvider>
    );
  }
}

export default ArchiveContainer;
