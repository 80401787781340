import * as React from "react";
import "./Emote.css";
import {EmoteConsumer} from "./EmoteContext";

interface EmoteProps {
  emote: string;
  emotes: {[name: string]: string};
}

const Emote: React.FunctionComponent<EmoteProps> = ({emote, emotes}) => {
  if (emote in emotes) {
    // Use current page scheme for img URL instead of specifying http(s)
    const src = emotes[emote].replace(/^https?:/, "");

    return <img className="Emote" src={src} alt={emote} />;
  }

  return <span>:{emote}:</span>;
};

const ConfiguredEmote = ({emote}: {emote: string}) => (
  <EmoteConsumer>
    {emotes => <Emote emote={emote} emotes={emotes} />}
  </EmoteConsumer>
);

export default ConfiguredEmote;
