import * as React from "react";
import {Link, Redirect} from "react-router-dom";
import auth from "../auth/auth";
import helpers from "../utils/form-helpers";
import AuthFields from "./AuthFields";
import "./Login.css";
import errors from '../utils/errors';

interface LoginState {
  email: string;
  password: string;
}

class Login extends React.Component<{}, LoginState> {
  handleChange = helpers.handleChange.bind(this);

  constructor(props: object) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  handleSubmit = (e: React.FormEvent) => errors.ignore(async () => {
    e.preventDefault();

    const {email, password} = this.state;
    if (email && password) {
      await auth.login(email, password);
    }
  });

  render() {
    if (auth.isAuthenticated()) {
      return <Redirect to="/" />;
    }

    const {email, password} = this.state;

    return (
      <div className="Login">
        <h3>Welcome back!</h3>
        <form onSubmit={this.handleSubmit}>
          <AuthFields
            email={email}
            password={password}
            onChange={this.handleChange}
          />

          <div className="form-item">
            <button>Log in</button>
          </div>

          <div className="registration-link">
            Not signed up yet? <Link to="/register">Register</Link> instead.
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
