import * as React from "react";
import settings from "./settings";

const {Provider, Consumer} = React.createContext<Settings | undefined>(
  undefined
);

export interface Settings {
  allow_guests_in_chat: boolean;
}

interface SettingsProps {
  settings?: Settings;
}

export class SettingsProvider extends React.Component<{}, SettingsProps> {
  state: SettingsProps = {};

  async componentDidMount() {
    const allSettings = await settings.get();

    this.setState({settings: allSettings});
  }

  render() {
    return (
      <Provider value={this.state.settings}>{this.props.children}</Provider>
    );
  }
}

export {Consumer as SettingsConsumer};
