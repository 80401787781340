import React, {useEffect, useState} from "react";
import {FaArrowDown} from "react-icons/fa";
import jumpToBottom from "./jump-to-bottom";
import "./Jump.css";

const Jump: React.FunctionComponent = () => {
  const [live, setLive] = useState(true);

  function onScroll(newLive: boolean) {
    // Slight delay seems to avoid choppiness while scrolling
    setTimeout(() => setLive(newLive), 200);
  }

  useEffect(() => {
    jumpToBottom.subscribeScroll(onScroll);

    return () => jumpToBottom.unsubscribeScroll(onScroll);
  });

  if (live) {
    return null;
  }

  return (
    <div className="Jump" onClick={jumpToBottom.jump}>
      <FaArrowDown /> Jump <FaArrowDown />
    </div>
  );
};

export default Jump;
