import * as React from "react";
import {Link, Route, Switch} from "react-router-dom";
import {UserConsumer} from "../auth/UserContext";
import {IgnoresConsumer} from "../posts/IgnoresContext";
import RoleGate, {roles} from "../router/RoleGate";
import errors from "../utils/errors";
import {UserItem} from "./User";
import "./UserActions.css";
import {default as userApi, default as users, Ignore} from "./users";

interface UserActionsProps {
  user: UserItem;
  getTooltipProps: (o: object) => object;
  tooltipRef: any;
}

const onBan = (user: UserItem) => () => {
  errors.ignore(async () => {
    await userApi.ban(user);
  });
};

function ignoreActions(ignores: Ignore[], user: UserItem) {
  const ignore = ignores.find(i => i.id === user.id);
  const setStatus = (status: string) => () =>
    errors.ignore(() => users.ignore(user.id, status));

  const ignoreUser = (
    <div className="action" onClick={setStatus("ignored")}>
      Ignore
    </div>
  );

  if (!ignore) {
    return (
      <React.Fragment>
        {ignoreUser}
        <div className="action" onClick={setStatus("images_only")}>
          Hide Images
        </div>
      </React.Fragment>
    );
  }

  if (ignore.images_only) {
    return (
      <React.Fragment>
        {ignoreUser}
        <div className="action" onClick={setStatus("unignored")}>
          Show Images
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="action" onClick={setStatus("unignored")}>
      Stop Ignoring
    </div>
  );
}

function viewingSelf(user: UserItem, me?: UserItem) {
  return me && me.id === user.id;
}

const UserActions: React.FunctionComponent<UserActionsProps> = ({
  getTooltipProps,
  tooltipRef,
  user
}) => {
  const {role, id} = user;

  return (
    <div {...getTooltipProps({ref: tooltipRef, className: "UserActions"})}>
      <Link to={`/settings/users/${id}`}>
        <div className="action">Profile</div>
      </Link>
      <Switch>
        <Route path="/chat/private" />
        <Link to={`/chat/private/${id}`}>
          <div className="action">Message</div>
        </Link>
      </Switch>

      {/* Don't show these actions for yourself, as they don't make sense */}
      <UserConsumer>
        {me =>
          viewingSelf(user, me) || (
            <React.Fragment>
              <IgnoresConsumer>
                {ignores => ignoreActions(ignores, user)}
              </IgnoresConsumer>
              <RoleGate role="moderator">
                {(role === roles.user || role === roles.member) && (
                  <div className="action" onClick={onBan(user)}>
                    Ban
                  </div>
                )}
              </RoleGate>
            </React.Fragment>
          )
        }
      </UserConsumer>
    </div>
  );
};

export default UserActions;
