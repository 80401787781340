import * as React from "react";
import {RefObject} from "react";
import Recaptcha from "react-google-recaptcha";
import {Link, Redirect} from "react-router-dom";
import auth from "../auth/auth";
import helpers from "../utils/form-helpers";
import AuthFields from "./AuthFields";

interface RegisterState {
  email: string;
  password: string;
  name: string;
  key: string;
}

class Register extends React.Component<{}, RegisterState> {
  captcha: RefObject<Recaptcha>;
  handleChange: typeof helpers.handleChange;

  constructor(props: object) {
    super(props);

    this.state = {
      email: "",
      password: "",
      name: "",
      key: ""
    };

    this.captcha = React.createRef();

    this.handleChange = helpers.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  async componentDidMount() {
    const key = await auth.getCaptchaKey();

    this.setState({key});
  }

  verifyCaptcha(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!this.captcha.current || !this.state.key) {
      return;
    }

    this.captcha.current.execute();
  }

  async handleSubmit(response: string | null) {
    const {email, name, password} = this.state;

    if (!email || !password || !name || !response) {
      return;
    }

    try {
      await auth.register(email, name, password, response);
    } catch (err) {
      if (this.captcha.current) {
        this.captcha.current.reset();
      }
    }
  }

  render() {
    if (auth.isAuthenticated()) {
      return <Redirect to="/" />;
    }

    const {email, name, password} = this.state;

    return (
      <div className="Login">
        <h3>Register new account</h3>
        <form onSubmit={this.verifyCaptcha}>
          <AuthFields
            email={email}
            password={password}
            onChange={this.handleChange}
          />

          <div className="form-item">
            <input
              type="text"
              name="name"
              placeholder="Display Name"
              value={name}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-item">
            <button>Register</button>
          </div>

          {this.state.key && (
            <Recaptcha
              ref={this.captcha}
              sitekey={this.state.key}
              size="invisible"
              onChange={this.handleSubmit}
            />
          )}

          <div className="registration-link">
            Already signed up? <Link to="/login">Log in</Link> instead.
          </div>
        </form>
      </div>
    );
  }
}

export default Register;
