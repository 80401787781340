import * as React from "react";
import {FaTimes} from "react-icons/fa";
import {Link} from "react-router-dom";
import "./CloseButton.css";

const CloseButton: React.FunctionComponent = () => (
  <Link to="/" className="CloseButton">
    <FaTimes />
  </Link>
);

export default CloseButton;
