import * as React from "react";

interface BanProps {
  expires?: string;
}

const BanExpiration: React.FunctionComponent<BanProps> = ({expires}) => {
  let message = "Active";
  if (expires === "never") {
    message = "Permanently Banned";
  } else if (expires) {
    const date = new Date(expires).toLocaleString();
    message = `Banned until ${date}`;
  }

  return <span>{message}</span>;
};

export default BanExpiration;
