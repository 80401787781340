import * as React from "react";
import {Link} from "react-router-dom";

const UserNotFound: React.FunctionComponent = () => (
  <div>
    <h2>User Not Found</h2>
    <p>
      Click <Link to="/settings/users">here</Link> to return to the user list
    </p>
  </div>
);

export default UserNotFound;
