import * as React from "react";
import GuestGate from "../common/GuestGate";
import ChatContainer from "./ChatContainer";
import RequestAccess from "./RequestAccess";

/**
 * Blocks guests from entering chat but allows them to request access
 */
const ChatWrapper: React.FunctionComponent = () => (
  <GuestGate spinner guest={<RequestAccess />}>
    <ChatContainer />
  </GuestGate>
);

export default ChatWrapper;
