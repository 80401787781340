import * as React from "react";
import CloseButton from "../common/CloseButton";
import LoadIndicator from "../common/LoadIndicator";
import {EmoteProvider} from "../emotes/EmoteContext";
import {PostItem} from "../posts/Post";
import PostList from "../posts/PostList";
import postService from "../posts/posts";
import errors from "../utils/errors";
import "./ChatmineContainer.css";

interface ChatmineContainerState {
  posts: PostItem[];
  loading: boolean;
}

class ChatmineContainer extends React.Component<{}, ChatmineContainerState> {
  state = {
    posts: [],
    loading: true
  };

  componentDidMount() {
    errors.ignore(async () => {
      const {posts} = await postService.getChatmine();
      this.setState({posts, loading: false});
    });
  }

  render() {
    const {posts, loading} = this.state;

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    return (
      <EmoteProvider>
        <div className="ChatmineContainer">
          <CloseButton />
          <h2>Chatmine</h2>
          <p>The best of RRTI since {oneWeekAgo.toLocaleDateString()}</p>
          {loading && <LoadIndicator />}
          <PostList posts={posts} readOnly />
        </div>
      </EmoteProvider>
    );
  }
}

export default ChatmineContainer;
