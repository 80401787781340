export type OnJump = () => void;

export default {
  /**
   * Helper for creating events that can be subscribed to
   *
   * To fire an event, you can call each listener with `listeners.forEach`
   */
  create<T>(): [T[], (listener: T) => void, (listener: T) => void] {
    const listeners: T[] = [];
    const subscribe = (listener: T) => listeners.push(listener);
    const unsubscribe = (listener: T) => {
      const idx = listeners.indexOf(listener);
      if (idx !== -1) {
        listeners.splice(idx);
      }
    };

    return [listeners, subscribe, unsubscribe];
  }
};
