/**
 * Contains methods for keeping the list of posts scrolled properly via JS
 *
 * Some methods specific to FF/IE where we can't use flex-direction: column-reverse due to bugs
 */
export default {
  /**
   * Keeps chat scrolled to the bottom as images load for non-webkit browsers
   */
  scrollOnImgLoad(
    wrapper: HTMLDivElement | null,
    scrollBottom: () => void,
    distance: number | null
  ) {
    if (!wrapper || distance === null) {
      return;
    }

    // don't scroll if we've moved away from the bottom
    if (!this.isLive(distance)) {
      return;
    }

    // scroll immediately whether there are images needing to load or not
    scrollBottom();

    const imgs = this.getImages(wrapper);
    if (!imgs) {
      return;
    }

    // if there are images to load, scroll again as they load
    imgs.forEach(img => {
      img.addEventListener("load", () => {
        // scroll as images load, but only if we haven't scrolled away from the bottom in the meantime
        if (this.areImagesLoaded(wrapper) && this.isLive(distance)) {
          scrollBottom();
        }
      });
    });
  },

  /**
   * Keeps chat scrolled to the bottom as the window is resized for non-webkit browsers
   */
  scrollOnResize(scrollBottom: () => void) {
    if (!this.isFirefoxOrIE()) {
      return;
    }

    window.addEventListener("resize", scrollBottom);
  },

  /**
   * Cleans up the listener created by `scrollOnResize`
   */
  cancelScrollOnResize(scrollBottom: () => void) {
    if (!this.isFirefoxOrIE()) {
      return;
    }

    window.removeEventListener("resize", scrollBottom);
  },

  /**
   * Returns all the image elements contained in posts
   */
  getImages(wrapper: HTMLDivElement): HTMLImageElement[] {
    return [].slice.call(wrapper.querySelectorAll("img"));
  },

  /**
   * Returns whether the images contained in posts are all done loading
   */
  areImagesLoaded(wrapper: HTMLDivElement) {
    const imgs = this.getImages(wrapper);

    return !imgs.some(img => !img.complete);
  },

  /**
   * Returns whether the browser is one that needs these hacks
   */
  isFirefoxOrIE() {
    const isFF = "sidebar" in window;
    const isIE = window.navigator.msPointerEnabled;

    return isFF || isIE;
  },

  /**
   * Returns the distance between the current scroll position and the bottom of the scrollable area
   */
  getScrollDistance(wrapper: HTMLDivElement) {
    const {scrollHeight, scrollTop, clientHeight} = wrapper;

    return scrollHeight - scrollTop - clientHeight;
  },

  /**
   * Returns whether the chat scroll position is close enough to the bottom to be considered "live"
   *
   * If not live, auto-scrolling should be disabled
   */
  isLive(distance: number, margin = 100) {
    return distance < margin;
  }
};
