import {ComponentType} from "react";
import Emotes from "./Emotes";
import Profile from "./Profile";

/**
 * Panes to show to all registered users
 */
const config: Array<[string, string, ComponentType]> = [
  ["Profile", "/settings/profile", Profile],
  ["Emotes", "/settings/emotes", Emotes]
];

export default config;
