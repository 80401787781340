import {PostItem} from "./Post";

type OnInsert = (text: string) => void;
type OnWrap = (wrapper: Wrapper) => void;
type Render = (selection: string) => string;
export type Wrapper = (selection: string) => WrapResult;

export interface WrapResult {
  replacement: string;
  start: number;
  end: number;
}

/**
 * Listeners for post input field insert events, fired when a component wants to modify the message
 */
const insertListeners: OnInsert[] = [];
const wrapListeners: OnWrap[] = [];

export default {
  /**
   * Listens for post events
   */
  subscribe(onInsert: OnInsert, onWrap: OnWrap) {
    insertListeners.push(onInsert);
    wrapListeners.push(onWrap);
  },

  /**
   * Unregisters listeners
   */
  unsubscribe(onInsert: OnInsert, onWrap: OnWrap) {
    const insertIdx = insertListeners.indexOf(onInsert);
    if (insertIdx !== -1) {
      insertListeners.splice(insertIdx);
    }

    const wrapIdx = wrapListeners.indexOf(onWrap);
    if (wrapIdx !== -1) {
      wrapListeners.splice(wrapIdx);
    }
  },

  /**
   * Fires an event to insert text into the post input
   */
  insert(text: string) {
    insertListeners.forEach(callback => callback(text));
  },

  /**
   * Fires an event to insert a quoted post into the post input
   */
  insertPost({post, poster}: PostItem) {
    let quote = post;
    // only block-quote if there are no code blocks
    if (!post.match(/```/)) {
      const lines = post.split(/\r?\n/);
      const quotedLines = lines.map(line => {
        // lines that are empty or just white space, don't block-quote
        if (!line.trim()) {
          return line;
        }

        return `> ${line}`;
      });

      quote = quotedLines.join("\n");
    }

    // Need two newlines at the end as one of them seems to be ignored
    this.insert(`${poster}:\n${quote}\n\n`);
  },

  /**
   * Fires an event to wrap the currently selected text based on a template
   *
   * Uses a placeholder (which can be empty) if the selection is empty
   *
   * @param {Render} wrap Receives the selection text and renders the final replacement value
   *
   * @see InsertSidebar for examples
   */
  wrapSelection: (placeholder: string) => (wrap: Render) => {
    const wrapWithPlaceholder = (selection: string) => {
      // Fall back to placeholder value if selection is empty
      const inner = selection || placeholder;

      // Apply the wrapper to the inner value, whether it's from a selection or a placeholder
      const replacement = wrap(inner);

      // Calculate distance from END of start and end points of inner value, used to create selection range
      const start = replacement.length - replacement.indexOf(inner);
      const end = start - inner.length;

      return {
        replacement,
        start,
        end
      };
    };

    // Notify listeners
    wrapListeners.forEach(callback => callback(wrapWithPlaceholder));
  }
};
