import * as React from "react";
import {FaBook, FaBug, FaCog, FaComments, FaTrophy} from "react-icons/fa";
import {Link} from "react-router-dom";
import Logout from "../auth/Logout";
import GuestGate from "../common/GuestGate";
import PrivateRoute from "../router/PrivateRoute";
import logo from "./aggro-eddie.png";
import "./Header.css";

const HeaderActions = () => (
  <div className="actions">
    <Link to="/" title="Chat">
      <FaComments />
    </Link>

    {/* Hide chatmine/archive for guests when guest access is off */}
    <GuestGate>
      <Link to="/chatmine" title="Chatmine">
        <FaTrophy color="gold" />
      </Link>
      <Link to="/archive" title="Archive">
        <FaBook />
      </Link>
    </GuestGate>

    <a
      href="https://gitlab.com/scunt/rrti/issues?sort=priority"
      title="Issues"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaBug />
    </a>

    <Link to="/settings" title="Settings">
      <FaCog />
    </Link>
  </div>
);

const Header = () => (
  <header className="Header">
    <span className="title-group">
      <Link to="/">
        <img src={logo} className="logo" alt="logo" />
        <h1 className="title">
          <span className="long">Riffle Raffle Thread Industries, Ltd.</span>
          <span className="short">RRTI</span>
        </h1>
      </Link>
    </span>

    <div className="right">
      <PrivateRoute passive component={HeaderActions} />
      <PrivateRoute passive component={Logout} />
    </div>
  </header>
);

export default Header;
