import * as React from "react";
import "./LoadIndicator.css";

const LoadIndicator: React.FunctionComponent = () => (
  <div className="LoadIndicator">
    <div className="inner" />
  </div>
);

export default LoadIndicator;
